<template>
  <div
    class="h-screen max-h-screen max-w-full flex flex-col justify-start items-center bg-white"
  >
    <Header class="mx-auto" />
    <div class="w-full shadow-xl">
      <Navbar />
    </div>
    <div class="relative flex-1 w-full max-w-full flex flex-row">
      <Sidebar
        class="h-full max-h-full"
        @toggle-sidebar="toggleSidebar"
        :collapsed="collapsed"
        :mobile="mobile"
      />
      <div
        class="relative h-full max-h-full w-full overflow-y-auto overflow-x-hidden"
      >
        <div
          class="absolute w-full h-full p-5"
          :class="{
            'transform translate-x-1/4 opacity-50': !collapsed && mobile,
          }"
        >
          <div
            v-if="collapsed && mobile"
            class="mb-5 mr-auto text-md flex justify-start text-blue-dark text-left"
            @click="toggleSidebar"
          >
            <a-icon type="menu" :style="{ fontSize: '22px' }" />
          </div>
          <h2 class="text-left text-3xl text-gray-600">
            <b>{{ title }}</b>
          </h2>
          <Breadcrumb />
          <div class="w-full py-5">
            <transition name="slide-fade" mode="out-in" appear>
              <router-view></router-view>
            </transition>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from "@/components/UI/Navbar/Navbar";
import Header from "@/components/UI/Header";
import Sidebar from "@/components/UI/Dashboard/Sidebar";
import Breadcrumb from "@/components/UI/Breadcrumb";
export default {
  components: {
    Navbar,
    Header,
    Breadcrumb,
    Sidebar,
  },
  data() {
    return {
      collapsed: true,
      mobile: true,
    };
  },
  created() {
    this.setMobile();
    window.addEventListener("resize", this.setMobile);
  },
  computed: {
    title: function () {
      return `${this.$route.meta.title || "Titulo"} ${
        this.$route.query[this.$route.meta.param] || ""
      }`;
    },
  },
  methods: {
    toggleSidebar() {
      this.collapsed = !this.collapsed;
    },
    setMobile() {
      if (window.screen.width < 900) {
        this.mobile = true;
      } else {
        this.mobile = false;
      }
    },
  },
};
</script>

<style></style>