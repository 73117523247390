<template>
  <div
    id="dashboard-sidemenu"
    class="h-full w-full max-w-xs p-5 flex flex-col justify-between text-white bg-blue-dark"
    :class="responsiveClasses"
  >
    <div
      v-if="!mobile"
      class="w-full mt-5 mb-5 flex justify-end border-b-2 text-left"
    >
      <div class="h-6 w-6 mb-5 p-1 rounded-full border">
        <div class="h-full w-full bg-orange rounded-full"></div>
      </div>
    </div>
    <div
      v-else
      class="w-full mt-5 mb-5 flex justify-end text-left"
      @click="toggleSidebar"
    >
      <a-icon v-if="!collapsed" type="arrow-left" />
    </div>
    <div
      v-if="!collapsed || !mobile"
      class="sidemenu-container relative flex-1 overflow-auto"
    >
      <div class="absolute h-full w-full text-left">
        <b>Secciones</b>
        <div v-for="(item, index) in menu" :key="index">
          <router-link
            v-if="item.show && !item.issub"
            :to="item.path"
            :class="item.path === $route.path ? 'router-link-exact-active' : ''"
            class="my-2 mr-2 py-2 px-2 flex justify-between items-center text-white hover:text-white hover:bg-gray-100 hover:bg-opacity-20 rounded-lg"
            @click.native="toggleSidebar(index)"
          >
            <span class="flex items-start justify-center gap-2">
              <a-icon
                :type="item.icon"
                :style="{ fontSize: '17px' }"
                class="font-normal"
              />
              {{ item.name }}
            </span>
            <div class="ml-1 dot"></div>
          </router-link>

          <div
            v-if="item.issub"
            class="my-2 mr-2 py-2 px-2 flex justify-between items-center text-white hover:text-white hover:bg-gray-100 hover:bg-opacity-20 rounded-lg cursor-pointer"
            :class="active == item.id ? 'bg-gray-50 bg-opacity-10' : ''"
            @click="toggleAccordion(item.id, index)"
          >
            <span class="flex items-start justify-center gap-2">
              <a-icon
                :type="item.icon"
                :style="{ fontSize: '17px' }"
                class="font-normal"
              />
              {{ item.name }}
            </span>
            <div class="ml-1 dot"></div>
            <span
              class="h-2 w-2 m-2 flex justify-center items-center transition"
            >
              <a-icon
                :type="item.showChildren ? 'caret-down' : 'caret-right'"
                :style="{ color: 'orange', fontSize: '20px' }"
              />
            </span>
          </div>
          <div
            v-if="item.issub && item.id == showId && accordionOpen"
            class="px-5"
          >
            <router-link
              v-for="(item2, index2) in item.children"
              :key="index2"
              :to="item2.path"
              class="my-2 mr-2 py-2 px-2 flex justify-between items-center text-white hover:text-white hover:bg-gray-100 hover:bg-opacity-20 rounded-lg"
              @click.native="toggleSidebar(index)"
            >
              <span class="flex items-start justify-center gap-2">
                <a-icon
                  :type="item2.icon"
                  :style="{ fontSize: '17px' }"
                  class="font-normal"
                />
                {{ item2.name }}
              </span>
              <div class="ml-1 dot"></div>
            </router-link>
          </div>
        </div>
        <!--
            <a-menu mode="inline" :open-keys="openKeys" style="width: 256px" @openChange="onOpenChange" class="bg-blue-dark" >
              <template v-for="item in menu">
              <a-menu-item v-if="!item.issub" :key="item.id">
                <router-link
                v-if="item.show"
                :to="item.path" 
                :class="item.path === $route.path ? 'router-link-exact-active' : ''"
                class="my-2 mr-2 py-2 px-2 flex justify-between text-white hover:text-white hover:bg-gray-100 hover:bg-opacity-20 rounded-lg"
                @click.native="toggleSidebar">
                <span class="flex items-center  gap-2">
                  <a-icon :type="item.icon" :style="{ fontSize: '17px'}" class="font-normal" />
                  {{item.name}}
                </span>
                <div class="ml-1 dot"></div>
              </router-link>
              </a-menu-item>

              <a-sub-menu v-if="item.issub" :key="item.id">
                <span slot="title">
                  <a-icon :type="item.icon" :style="{ fontSize: '17px'}" class="font-normal" />
                  <span>{{item.name}}</span>
                  <div class="ml-1 dot"></div>
                </span>
                <a-menu-item v-for="item2 in item.children" :key="item2.id">
                  <a-icon :type="item2.icon" :style="{ fontSize: '17px'}" class="font-normal" />
                  <span>{{item2.name}}</span>
                </a-menu-item>
              </a-sub-menu>
              </template>
</a-menu>
-->
      </div>
    </div>
    <div
      v-if="!mobile || !collapsed"
      class="w-full mb-2 mt-5 px-2 py-2 border-t-2 text-center flex items-center"
    >
      <span
        class="flex justify-center h-10 rounded-lg items-center gap-2 cursor-pointer hover:bg-gray-200 hover:bg-opacity-20 w-full"
        @click="logout"
      >
        <a-icon
          type="logout"
          :style="{ fontSize: '17px' }"
          class="font-normal"
        />
        Cerrar sesión
      </span>
    </div>
  </div>
</template>

<script>
import menuList from "./sidemenu.links";
export default {
  props: ["collapsed", "mobile"],
  data() {
    return {
      rootSubmenuKeys: ["sub1", "sub2", "sub4"],
      openKeys: ["sub1"],
      accordionOpen: false,
      showId: null,
      active: null,
    };
  },
  computed: {
    menu() {
      // let menu = []
      // this.$store.state.session?.modules?.forEach(item => {
      //     menu.push({
      //         title: item.name,
      //         path: item.path || "role",
      //         icon: item.icon || "file-unknown",
      //         show: true,
      //         submenu: []
      //     })
      //   });
      //   let test = false;
      //   this.createMenu();

      // Un proceso repetitivo hasta construir todos los niveles posibles del menú
      if (!this.$store.state.session?.modules?.length) {
        return [
          {
            title: "Inicio",
            path: "/dashboard/home",
            icon: "home",
            show: true,
            submenu: [],
          },
        ];
      }
      const menuArray = this.$store.state.session?.modules;
      let menu = [];
      // 1. Nivel 1
      menu = menuArray
        ?.filter((item) => item.parent_id === null)
        .map((item) => {
          return {
            ...item,
            issub: false,
            path: item.path || "role",
            icon: item.icon || "file-unknown",
            show: true,
            children: [],
          };
        });
      // 2. Nivel 2
      const auxLevel2 = this.groupBy(
        menuArray.filter((item) => item.parent_id),
        "parent_id"
      );
      // 3. Agregar submenu a los ítems
      menu.forEach((item) => {
        if (auxLevel2[item.id]) {
          item.issub = true;
          item.showChildren = false;
          item.children = auxLevel2[item.id];
        }
      });
      this.sortMenu(menu);
      // Extra
      menu.unshift({
        name: "Inicio",
        path: "/dashboard/home",
        icon: "home",
        show: true,
        issub: false,
        submenu: [],
        children: [],
      });
      const userIsAdmin = true;
      // Eliminar childrenData
      const childrenData = [
        {
          id: 1,
          name: "Informes de gestión",
          icon: "audit",
          path: "/dashboard/annual-report",
        },
        {
          id: 2,
          name: "Registro informe anual",
          icon: "audit",
          path: "/dashboard/register-annual-report",
        },
      ];
      const output = menu.map((itemMap) => {
        // Sólo retomar itemMap y eliminar el condicional.
        // return itemMap;
        if (itemMap.name === "Defensor del Consumidor Financiero") {
          return {
            ...itemMap,
            children: childrenData,
          };
        } else {
          return itemMap;
        }
      });

      return [...output];
    },
    responsiveClasses() {
      if (this.mobile && this.collapsed) {
        return "hidden";
      } else if (this.mobile && !this.collapsed) {
        return "block";
      }
    },
  },
  methods: {
    logout() {
      this.$router.push("/logout");
    },
    toggleSidebar(index) {
      this.itemActive(index);
      if (this.mobile) {
        this.$emit("toggle-sidebar");
      }
    },
    sortMenu(menu) {
      let sortedMenu = menu.sort((itemA, itemB) => {
        if (itemA.name > itemB.name) return 1;
        if (itemA.name < itemB.name) return -1;
        return 0;
      });
      sortedMenu.forEach((menuItem) => {
        if (menuItem.children) {
          this.sortMenu(menuItem.children);
        }
      });
      return sortedMenu;
    },
    onOpenChange(openKeys) {
      const latestOpenKey = openKeys.find(
        (key) => this.openKeys.indexOf(key) === -1
      );
      if (this.rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
        this.openKeys = openKeys;
      } else {
        this.openKeys = latestOpenKey ? [latestOpenKey] : [];
      }
    },
    createMenu() {},
    groupBy(xs, key) {
      return xs.reduce(function (rv, x) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
      }, {});
    },
    toggleAccordion(itemId) {
      this.menu.forEach((item) => {
        if (item.id != itemId && item.showChildren) item.showChildren = false;
        else if (item.id == itemId) item.showChildren = !item.showChildren;
      });
      if (this.showId === itemId) {
        this.accordionOpen = !this.accordionOpen;
      } else this.accordionOpen = true;

      this.showId = itemId;
    },
    itemActive(index) {
      this.active = this.menu[index].id;
    },
    searchItemActive() {
      this.menu.forEach((item) => {
        if (item.issub) {
          item.children.forEach((subItem) => {
            if (subItem.path === this.$route.path) {
              this.active = item.id;
              this.toggleAccordion(item.id);
            }
          });
        }
      });
    },
  },
  created() {
    this.searchItemActive();
  },
};
</script>

<style></style>